import Request from '@/services/Request';
import axios from 'axios';

export default class FileService {
	static async uploadFile(file) {
		const host = window.location.hostname;
		let url = '';

		url = 'http://' + host + ':8080';

		if (!window.location.host.includes('localhost') && !window.location.host.includes('192.168')) {
			url = 'https://' + host;
		}

		try {
			const response = await axios.post('/api/storage/image', file, {
				headers: {
					'Content-type': 'multipart/form-data',
				},
			});
			return response.data;
		} catch (error) {
			return error;
		}
	}
	static getUploadedFile(file_name) {
		return Request.request('GET', `/storage/${file_name}`);
	}
	static getFile(file_id) {
		return Request.request('GET', `/api/file/${file_id}`);
	}
}
