<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div v-bind:class="{

  }">
    <section class="step-wizard">
      <ul class="step-wizard-list">
        <li v-bind:class="{'step-wizard-item': true, 'current-item': stage === 0, }">
          <span class="progress-count "></span>
          <span class="progress-label d-none d-md-block">{{ step1 }}</span>
        </li>
        <li v-bind:class="{'step-wizard-item': true, 'current-item': stage === 1, }">
          <span class="progress-count"></span>
          <span class="progress-label d-none d-md-block">{{ step2 }}</span>
        </li>
        <li v-bind:class="{'step-wizard-item': true, 'current-item': stage === 2, }">
          <span class="progress-count"></span>
          <span class="progress-label d-none d-md-block">{{ step3 }}</span>
        </li>
      </ul>
    </section>
  </div>
</template>

