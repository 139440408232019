
export default {
    name: 'StepProgressBar',
    props: {
        stage: {
            type: Number,
            default: 0,
        },
        step1: {
            type: String,
            default: ''
        },
        step2: {
            type: String,
            default: ''
        },
        step3: {
            type: String,
            default: ''
        }
    },
    components: {

    },
    mounted() {
    }
}